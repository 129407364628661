var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[(_vm.ExistsCompany)?_c('section',[_c('LinkFirstCompanyExists',{attrs:{"userEmail":_vm.emailUsuarioEmpresa,"cnpjEmpresa":_vm.form.document,"tipoDocumento":_vm.tipoDocumentoEmpresa},on:{"linkCompany":_vm.handleLinkAnotherCompany}})],1):_vm._e(),(!_vm.ExistsCompany)?_c('b-card',[_c('h2',[_vm._v("Vincular uma empresa")]),(_vm.firsCompany)?_c('p',{staticClass:"mb-0"},[_vm._v(" Para estar apto a usar a plataforma VacinaSESI você precisa estar vinculado a uma empresa. ")]):_vm._e(),_c('p',[_vm._v("Insira as informações necessárias para continuar.")]),_c('b-alert',{staticClass:"p-1",attrs:{"show":_vm.form.errorApi.error ? true : false}},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]),_c('validation-observer',{ref:"linkFirstCompanyRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CNPJ ou CPF*","label-for":"cnpj","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"cnpj","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(
                    _vm.form.document.length <= 14
                      ? '###.###.###-##'
                      : '##.###.###/####-##'
                  ),expression:"\n                    form.document.length <= 14\n                      ? '###.###.###-##'\n                      : '##.###.###/####-##'\n                  "}],attrs:{"state":errors.length > 0 ? false : null,"id":"description-input","placeholder":"00.000.000/0000-00"},on:{"input":_vm.validateDocument},model:{value:(_vm.form.document),callback:function ($$v) {_vm.$set(_vm.form, "document", $$v)},expression:"form.document"}}),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.dadosPrincipais.documento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.documento)+" ")]):_vm._e()]}}],null,false,90701453)})],1)],1)],1),(_vm.cnpjDocument)?_c('section',[_c('b-row',[_c('b-col',{staticClass:"pr-0 mt-2",attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome Fantasia*","label-for":"fantasyName","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"fantasyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"description-input","placeholder":"Nome fantasia da empresa"},model:{value:(_vm.form.fantasyName),callback:function ($$v) {_vm.$set(_vm.form, "fantasyName", $$v)},expression:"form.fantasyName"}}),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.dadosPrincipais.nome_fantasia)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.nome_fantasia)+" ")]):_vm._e()]}}],null,false,2773264778)})],1)],1),_c('b-col',{staticClass:"pr-0 mt-2",attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Razão Social*","label-for":"companyName","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"companyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"description-input","placeholder":"Razão social da empresa"},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}}),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.dadosPrincipais.razao_social)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.razao_social)+" ")]):_vm._e()]}}],null,false,1748081739)})],1)],1)],1)],1):_vm._e(),(_vm.cpfDocument)?_c('section',[_c('b-row',[_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nome Completo*","label-for":"name-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"name-input","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('X'.repeat(255)),expression:"'X'.repeat(255)"}],attrs:{"state":errors.length > 0 ? false : null,"id":"name-input","placeholder":"Nome completo"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.dadosPrincipais.nome)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.nome)+" ")]):_vm._e()]}}],null,false,3735432664)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"Inscrição estadual*","label-for":"stateRegistration-input","label-class":"font_size_label_empresa"}},[_c('validation-provider',{attrs:{"name":"stateRegistration-input","rules":"required|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###'),expression:"'###.###.###'"}],attrs:{"state":errors.length > 0 ? false : null,"id":"inscricao-estadual-input","placeholder":"Inscrição estadual da empresa"},model:{value:(_vm.form.stateRegistration),callback:function ($$v) {_vm.$set(_vm.form, "stateRegistration", $$v)},expression:"form.stateRegistration"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.dadosPrincipais.inscricao_estadual)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.inscricao_estadual)+" ")]):_vm._e()]}}],null,false,3841957959)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('validation-provider',{attrs:{"name":"Sexo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sexo*","label-for":"gender","label-class":"font_size_label_empresa","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"status","options":['Masculino', 'Feminino'],"variant":"custom","item-text":"description","item-value":"value","placeholder":"Selecione o sexo","label":"description"},model:{value:(_vm.form.inputGender),callback:function ($$v) {_vm.$set(_vm.form, "inputGender", $$v)},expression:"form.inputGender"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.genero)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.genero)+" ")]):_vm._e()],1)]}}],null,false,3638768164)})],1),_c('b-col',{attrs:{"xl":"4"}},[_c('validation-provider',{attrs:{"name":"DataNascimento","rules":"required|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data de Nascimento*","label-for":"birthdate-input","label-class":"font_size_label_empresa"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null,"id":"birthdate-input","placeholder":"00/00/0000","autocomplete":"off"},on:{"input":_vm.setBirthDateInput},model:{value:(_vm.inputBirthDate),callback:function ($$v) {_vm.inputBirthDate=$$v},expression:"inputBirthDate"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de nascimento","button-variant":"custom-blue","clearable":""},on:{"input":_vm.setBirthDatePicker},model:{value:(_vm.form.birthDate),callback:function ($$v) {_vm.$set(_vm.form, "birthDate", $$v)},expression:"form.birthDate"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.dadosPrincipais.data_nascimento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.data_nascimento)+" ")]):_vm._e()],1)]}}],null,false,1747039539)})],1),_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nome da mãe","label-class":"font_size_label_empresa nome_mae"}},[_c('validation-provider',{attrs:{"name":"nome_mae"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.nome_mae
                        ? false
                        : null,"id":"nome_mae-input","placeholder":"Nome da mãe"},model:{value:(_vm.form.nomeMae),callback:function ($$v) {_vm.$set(_vm.form, "nomeMae", $$v)},expression:"form.nomeMae"}}),(_vm.form.errorApi.dadosPrincipais.nome_mae)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.nome_mae)+" ")]):_vm._e(),(_vm.form.errorApi.dadosPrincipais.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.dadosPrincipais.error)+" ")]):_vm._e()]}}],null,false,886446603)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('NacionalidadeField',{attrs:{"idNacionalidade":_vm.form.idNacionalidade,"errorApiIdNacionalidade":_vm.form.errorApi.dadosPrincipais.id_nacionalidade,"customClass":"link-first-company"},on:{"updateNacionalidade":_vm.updateNacionalidade}})],1)],1)],1):_vm._e()],1)],1),_c('b-button',{staticClass:"mr-2 mt-4",attrs:{"id":"save-first-company","variant":"custom-blue","disabled":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.validaCompanyData()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Continuar")]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }