<template>
    <b-card>
        <b-alert show variant="danger">
            <div class="alert-body">
                <feather-icon  class="mr-25" icon="AlertCircleIcon" />
                <strong class="ml-25 text-danger">Erro ao vincular empresa</strong>
            </div>
        </b-alert>

        <h2>A empresa com o {{ tipoDocumento.toUpperCase()+' '+cnpjEmpresa }} já está cadastrada.</h2>

        <p class="font-14">Entre em contato com o usuário {{ $helpers.maskEmailForShow(userEmail) }} da sua empresa e solicite para ser vinculado a ela.</p>

        <p class="font-14">Caso não seja possível, você pode entrar em contato com o nosso suporte.</p>

        <div>
            <b-button id="new-company-link" 
                class="mr-2 mt-2" 
                variant="custom-blue"
                @click.prevent="redirectToLinkCompany()"
            >
                <feather-icon icon="PlusIcon" class="mr-50"/>
                <span class="align-middle">Vincule-se a outra Empresa</span>
            </b-button>

            <b-button id="contact-support" 
                class="mr-2 mt-2 ml-2" 
                variant="custom-blue"
                @click.prevent="redirectToSupport()"
            >
                <feather-icon icon="UserIcon" class="mr-50"/>
                <span class="align-middle">Contato Suporte</span>
            </b-button>
        </div>
    </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BAlert } from 'bootstrap-vue';

export default {
    components: {
        BCard, BRow, BCol, BButton, BAlert
    },

    props: ['userEmail', 'cnpjEmpresa', 'tipoDocumento'],

    methods: {
        redirectToLinkCompany() {
            this.$emit('linkCompany');
        },

        redirectToSupport() {
            this.$router.push({ name: 'pages-faq'});
        }
    }
}
</script>

<style scoped>
.font-14 {
    font-size: 14px;
}
</style>